import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import Login from 'features/auth/login/Login'
import Channels from 'features/channels/Channels'
import Messages from 'features/messages/Messages'
import VideoCall from 'features/video-call/VideoCall'
import ProtectedMainLayout from './ProtectedMainLayout'
import Conversation from 'features/messages/Conversation'
import SystemSettings from 'features/settings/SystemSettings'
import ChannelsConversation from 'features/channels/Conversation'
import Dashboard from 'features/settings/dashboard/Dashboard'
import ResetPassword from 'features/auth/reset-password/ResetPassword'
import Register from 'features/auth/register/Register'
import Invite from 'features/invite/Invite'
import ProtectedAdminRoute from './ProtectedAdminRoute'
import LdapManagement from 'features/settings/system/ldap-management/LdapManagement'
import RolesManagement from 'features/settings/system/role-management/RolesManagement'
import PasswordManagement from 'features/settings/system/password-management/PasswordManagement'
import MailManagement from 'features/settings/system/mail-management/MailManagement'
import SystemManagement from 'features/settings/system/systemManagement/SystemManagement'
import UserManagement from 'features/settings/management/user-managment/UserManagement'
import GroupManagement from 'features/settings/management/group-management/GroupManagement'
import ChannelManagement from 'features/settings/management/channel-management/ChannelManagement'
import { useAppDispatch } from 'app/hooks'
import { useLazyGetPasswordQuery, useLazyGetSystemQuery } from 'app/services/system.service'
import { useEffect } from 'react'
import { systemActions } from 'app/slices/system.slice'
import { hashActions } from 'app/slices/hash.slice'
import NewsFeedLayout from 'features/news-feed/NewsFeedLayout'
import NewsFeed from 'features/news-feed/NewsFeed'
import PostDetail from 'features/news-feed/post-detail/PostDetail'
import LiveStream from 'features/live-stream/LiveStream'
import InitialSetupForm from 'features/init'
import { getDataFromDB } from 'utils/helpers/storageHelper'
import RolesUserManagement from 'features/settings/system/role-management/RolesUserManagement'
import RolePermissionManagement from 'features/settings/system/role-management/RolePermissionManagement'
import RoleMessagingManagement from 'features/settings/system/role-management/RoleMessagingManagement'

const Index = () => {

  return (
    <Routes>
      <Route path="/" element={<ProtectedMainLayout />}>
        <Route path="news-feed" element={<NewsFeedLayout />}>
          <Route index element={<NewsFeed />} />
          <Route path=":postId" element={<PostDetail />} />
        </Route>
        <Route path="news-feed/:postId" element={<NewsFeedLayout />}></Route>
        <Route path="Channels" element={<Channels />}>
          <Route path=":channelId" element={<ChannelsConversation />} />
        </Route>
        <Route
          path="system-settings"
          element={
            <ProtectedAdminRoute>
              <SystemSettings />
            </ProtectedAdminRoute>
          }
        >
          <Route
            path="server-domain-management"
            element={
              <ProtectedAdminRoute>
                <SystemManagement />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="ldap-management"
            element={
              <ProtectedAdminRoute>
                <LdapManagement />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="role-management"
            element={
              <ProtectedAdminRoute>
                <RolesManagement />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="role-management/users/:roleId"
            element={
              <ProtectedAdminRoute>
                <RolesUserManagement />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="role-management/permissions/:roleId"
            element={
              <ProtectedAdminRoute>
                <RolePermissionManagement />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="role-management/messaging/:roleId"
            element={
              <ProtectedAdminRoute>
                <RoleMessagingManagement />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="password-management"
            element={
              <ProtectedAdminRoute>
                <PasswordManagement />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="mail-management"
            element={
              <ProtectedAdminRoute>
                <MailManagement />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="user-management"
            element={
              <ProtectedAdminRoute>
                <UserManagement />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="group-management"
            element={
              <ProtectedAdminRoute>
                <GroupManagement />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="channel-management"
            element={
              <ProtectedAdminRoute>
                <ChannelManagement />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="dashboard"
            element={
              <ProtectedAdminRoute>
                <Dashboard />
              </ProtectedAdminRoute>
            }
          />
        </Route>
        <Route path="messages" element={<Messages />}>
          <Route path=":roomId" element={<Conversation />} />
        </Route>
        <Route path="video-call" element={<VideoCall />} />
        {/* <Route path="live-stream" element={<LiveStream />} /> */}
        <Route path="*" element={<Navigate to="/news-feed" />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/init" element={<InitialSetupForm />} />
      <Route path="/invite/:inviteId" element={<Invite />} />
      <Route path="/invite/:inviteId/:roomId" element={<Invite />} />
      <Route path="/reset-password/:inviteId" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  )
}

export default Index
