import { useEffect } from 'react'
import { v4 } from 'uuid'
import { PersistGate } from 'redux-persist/integration/react'

import Routes from 'routes'
import getBaseUrl from 'utils/helpers/apiHelper'
import useBroadcastChannel from 'utils/hooks/useBroadcastChannel'
import { persistor } from 'app/store'
import { SocketProvider } from 'app/socket/socketContext'
import { notificationChannel } from 'utils/constants'
import { useGetConfigMutation } from 'app/services/system.service'
import { useRegisterClientMutation } from 'app/services/auth.service'

import Loading from 'components/spinner/Loading'
import FileModal from 'components/modals/FileModal'
import ProgressBar from 'components/progress-bar/ProgressBar'
import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import ConferenceAlert from 'components/conference-alert/ConferenceAlert'
import DropdownSocket from 'components/dropdown-socket/DropdownSocket'
import { clearDB, getDataFromDB, setDataToDB } from 'utils/helpers/storageHelper'
import '@progress/kendo-theme-default/dist/all.css';
import { useAppDispatch } from 'app/hooks'
import { hashActions } from 'app/slices/hash.slice'
import { useNavigate } from 'react-router-dom'
import { systemActions } from 'app/slices/system.slice'
import notification from 'utils/helpers/notification'

function App() {
  const dispatch = useAppDispatch()
  const [config] = useGetConfigMutation()
  const [registerClientMutation] = useRegisterClientMutation()

  useBroadcastChannel()

  useEffect(() => {
    getConfig()
    registerClient()
    notificationChannel.postMessage({ type: 'API_BASE_URL', payload: getBaseUrl() + 'api/' })
  }, [])

  const navigate = useNavigate()
  const setNameAndFavicon = (name: string, url: string, logo: string) => {
    document.title = name
    document.getElementById('favicon')?.setAttribute('href', `${url}`)
    document.getElementById('login-logo')?.setAttribute('src', `${logo}`)
    document.getElementById('login-logo')?.setAttribute('srcSet', `${logo}`)
  }

  const getConfig = async () => {
    const {data :configData}  = (await config({})) as any //{ data: { salt: string } }
    if (!configData?.isSystemInit) {
      navigate('/init')
    }else {
      navigate('/login')
    }
    await notification.registerWebPushServiceWorker()
    if (configData) {
      setNameAndFavicon(configData?.name, configData?.imageUrlFavicon, configData?.imageUrlLogo)
      dispatch(systemActions.updateSystem(configData))
      setDataToDB('vimesoft', 'config', configData)
    }
  }

  const registerClient = async () => {
    const existingClientId = await getDataFromDB('vimesoft', 'clientId') as string | null;
    if (existingClientId && existingClientId.trim() !== '') return;
    try {
        const v4Key = v4();
        await setDataToDB('vimesoft', 'clientUUID', v4Key);
        const clientData = await registerClientMutation({
            clientGeneratedUUID: v4Key,
        }) as { data: { clientId: string } };

        if (clientData && clientData.data && clientData.data.clientId) {
            await setDataToDB('vimesoft', 'clientId', clientData.data.clientId);
            dispatch(hashActions.updateHashClientId(clientData.data.clientId));
        }
    } catch (error) {
        console.error("Error registering client", error);
    }
};



  return (
    <PersistGate loading={null} persistor={persistor}>
      <SocketProvider>
        <Routes />
        <ProgressBar />
        <DropdownAlert ref={(ref: DropdownAlert) => (DropdownAlert.ref = ref)} />
        <DropdownSocket ref={(ref: DropdownSocket) => (DropdownSocket.ref = ref)} />
        <ConferenceAlert />
        <FileModal ref={(ref: FileModal) => (FileModal.ref = ref)} />
        <Loading ref={(ref: Loading) => (Loading.ref = ref)} />
      </SocketProvider>
    </PersistGate>
  )
}

export default App
