import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { icons } from 'utils/icons'
import { POST } from 'locales/locales'
import { InputData } from 'app/slices/news-feed.slice'

import PostHeader from '../../_components/post-header/PostHeader'
import CustomFile from 'components/file/CustomFile'
import CustomIcon from 'components/icon/CustomIcon'
import CommonInput from '../../_components/common-input/CommonInput'
import { MOBILE_DEVICE_WIDTH } from 'utils/constants'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'
import { TextBox } from '@progress/kendo-react-inputs'


interface ICreateNewPostModalContentProps {
  inputData: InputData
  avatar: string
  fullname: string
  isValid: boolean
  isPostLoading: boolean
  header: string
  onChangeInputHeader: (e: FixMeLater) => void
  onClose: () => void
  onNewPost: () => void
  onCancelFile: (index: number) => void
  onSelectEmoji: (e: FixMeLater) => void
  onChangeInputData: (text: string) => void
}

const CreateNewPostModalContent = ({
  inputData,
  avatar,
  fullname,
  isValid,
  isPostLoading,
  header,
  onChangeInputHeader,
  onClose,
  onNewPost,
  onCancelFile,
  onSelectEmoji,
  onChangeInputData,
}: ICreateNewPostModalContentProps) => {
  const { t } = useTranslation()
  const { screenWidth } = useWindowDimensions()

  const [mentionsDropdown, setMentionsDropdown] = useState(false)

  const onKeyUp = async (event: FixMeLater) => {
    event.preventDefault()

    if (
      event.key === 'Enter' &&
      isValid &&
      !event.shiftKey &&
      !mentionsDropdown &&
      !isPostLoading
    ) {
      onNewPost()
      onClose()
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
    }
  }

  return (
    <Wrapper>
      <PostHeader avatar={avatar} fullname={fullname} />
      <div style={{ marginTop: '10px' }}>
        <TextBox
          value={header}
          onChange={onChangeInputHeader}
          style={{ fontSize: 16, borderRadius: 25, padding: '6px 10px'}}
          placeholder="Başlık Giriniz..."
        />
      </div>
      <CommonInput
        id={'common_input'}
        textInfo
        data={inputData}
        placeHolder={t(POST.CREATE_POST)}
        onMentionDropDown={setMentionsDropdown}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onSelectEmoji={onSelectEmoji}
        onChangeInputData={onChangeInputData}
      />
      {inputData?.files && inputData.files.length > 0 && (
        <Layout onClick={(event) => event.stopPropagation()}>
          {inputData.files.map((file: FixMeLater, index: number) => {
            const url: string = file?.url || URL.createObjectURL(file)
            const name = file.originalName
            const mimetype = file?.mimetype || file.type
            const size = file?.size
            return (
              <FileMessageWrapper key={file?._id || file?.lastModified}>
                <CloseIcon
                  id="cancel-file"
                  iconPreset="small"
                  source={icons.closeIconWhite}
                  isCancel={false}
                  onClick={() => onCancelFile(index)}
                />
                <CustomFile
                  index={index}
                  sentAt={file.sentAt}
                  source={url}
                  mimetype={mimetype}
                  preset={screenWidth > MOBILE_DEVICE_WIDTH ? 'editPost' : 'editPostResponsive'}
                  size={size}
                  filename={name}
                  thumbnail={file?.thumbnail}
                />
              </FileMessageWrapper>
            )
          })}
        </Layout>
      )}
    </Wrapper>
  )
}

export default CreateNewPostModalContent

const Wrapper = styled.div``

const Layout = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`
const FileMessageWrapper = styled.div`
  position: relative;
  #cancel-file {
    display: flex;
  }
  #loading-file {
    display: none;
  }
`

const CloseIcon = styled(CustomIcon).attrs((props: { isCancel: boolean }) => props)`
  display: ${({ isCancel }) => (isCancel ? 'flex' : 'none')};
  position: absolute;
  top: -5px;
  right: -5px;
  flex-shrink: 0;
  background-color: ${colors.vime};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  z-index: 2;
`
