import { useEffect, useState } from 'react'

import uploadApi from 'app/axiosApi'
import { uploadActions } from 'app/slices/upload.slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { conversationActions } from 'app/slices/conversations.slice'
import DropdownAlert from 'components/dropdown-alert/DropdownAlert'

export default function useHandleFileQueue() {
  const [progress, setProgress] = useState<number | null>(null)
  const [fileName, setFileName] = useState('')
  const [hasUpload, setHasUpload] = useState(false)

  const dispatch = useAppDispatch()
  const { queue } = useAppSelector((state) => state.upload)

  const onUploadProgress = (event: ProgressEvent) => {
    setProgress(Math.round((event.loaded * 100) / event.total))
  }

  const uploadFile = async () => {
    const data = queue[0]
    setHasUpload(true)
    try {
      console.warn('ALLOA');
      setProgress(0)
      setFileName(data.file.name)
      const { data: result } = await uploadApi.uploadFile(
        'files/?from=room',
        [data.file],
        'file',
        onUploadProgress,
      )
      // if (
      //   data.file.type.includes(';')
      //     ? data.file.type.split(';')[0] !== result.data.mimetype
      //     : data.file.type !== result.data.mimetype
      // ) {
      //   dispatch(
      //     conversationActions.removeDataByUniqueIdInDraft({
      //       id: data.id,
      //       uniqueId: data.uniqueId,
      //     }),
      //   )
      //   return DropdownAlert.ref.show({
      //     isError: true,
      //     message: navigator.language.includes('en')
      //       ? `Encountered an error while uploading ${data.file.name}.`
      //       : `${data.file.name} adlı dosya yüklenirken hata oluştu.`,
      //   })
      // }
      dispatch(
        conversationActions.updateDraftData({
          id: data.id,
          startAt: data.startAt,
          fileInfo: result.data,
          uniqueId: data.uniqueId,
        }),
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.warn('ERROR', error);
      dispatch(
        conversationActions.removeDataByUniqueIdInDraft({
          id: data.id,
          uniqueId: data.uniqueId,
        }),
      )
      let errorMessage = error.message;
      if(error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      DropdownAlert.ref.show({
        isError: true,
        message: navigator.language.includes('en')
          ? `Encountered an error while uploading ${data.file.name}: ${errorMessage}`
          : `${data.file.name} adlı dosya yüklenirken hata oluştu: ${errorMessage}`,
      })
    } finally {
      dispatch(uploadActions.removeFromQueue({ uniqueId: data.uniqueId }))
      setProgress(null)
      setFileName('')
      setHasUpload(false)
    }
  }

  useEffect(() => {
    if (!hasUpload && queue?.[0]) {
      uploadFile()
    }
  }, [queue, hasUpload])

  return { progress, fileName }
}
