import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector,useIsElectron} from 'app/hooks'
import {
  useLazyCheckConferenceActiveQuery,
  useLazyRejectAllConferenceQuery,
  useLazyUpdateActiveConferenceQuery,
} from 'app/services/conference.service'
import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { BUTTONS, PLACEHOLDERS } from 'locales/locales'
import {
  CONFERENCE_BLANK_DIMENSIONS,
  CONFERENCE_TYPE_VIDEO,
  MESSAGE_DATE_FORMAT,
} from 'utils/constants'
import { conferenceActions, TConferenceAlert } from 'app/slices/conference.slice'

import Loading from 'components/spinner/Loading'
import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import CustomButton from 'components/button/CustomButton'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'

interface IAlertContainerProps {
  isVisible: boolean
  isExpand: boolean
}

interface IAlertProps {
  index: number
  isExpand: boolean
  length: number
}
const ConferenceAlert = () => {
  const isElectron = useIsElectron();
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const conferenceDetails = useAppSelector((state) => state.conference)
  const [windowApi,setWindowApi] = useState<any>(null);

  const [rejectAll] = useLazyRejectAllConferenceQuery()
  const [checkConference] = useLazyCheckConferenceActiveQuery()
  const [updateConference] = useLazyUpdateActiveConferenceQuery()

  const [isExpand, setIsExpand] = useState(false)

  useEffect(() => {
    const w = window as any;
    if (w.api)
      setWindowApi(w.api);
    if (conferenceDetails.visible && windowApi) {
      conferenceDetails.data.forEach((conference: { isActive: boolean; title: string; conferenceType: string, url: string }) => {
          const notifyType = conference.conferenceType === 'video' ? 'videoConference' : 'audioConference';
          windowApi.send('sendConferenceNotify', {
            type: notifyType,
            title: notifyType === 'videoConference' ? t('MESSAGE_VIDEO_CALL') : t('MESSAGE_AUDIO_CALL'),
            msg: conference.title,
            url: conference.url
          });
      });
    }
  }, [conferenceDetails.data, conferenceDetails.visible, windowApi, t]);

  const onClickShowLess = () => {
    setIsExpand(false)
  }

  const onClickAlert = async (url: string, index: number, meetingId: string) => {
    if (!isExpand && conferenceDetails.data.length !== 1) {
      return setIsExpand(true);
    }
  
    if (isElectron && windowApi) {
      const conference = conferenceDetails.data.find((c: { meetingId: string }) => c.meetingId === meetingId);
      if (conference) {
        const notifyType = conference.conferenceType === CONFERENCE_TYPE_VIDEO ? 'videoConference' : 'audioConference';
        windowApi.send('sendVideoCallClick', {
          type: notifyType,
          title: notifyType === 'videoConference' ? t('MESSAGE_VIDEO_CALL') : t('MESSAGE_AUDIO_CALL'),
          msg: conference.title,
          url: conference.url
        });
      }

      return;
    }
  
    try {
      Loading.ref.show();
      const data = await checkConference({ meetingId });
      if (data.data?.isActive) {
        await updateConference({ meetingId });
        window.open(url, '', CONFERENCE_BLANK_DIMENSIONS);
      }
    } finally {
      Loading.ref.hide();
    }
  };
  


  const onClickCloseIcon = async (index: number, meetingId: string) => {
    try {
      await updateConference({ meetingId })
      dispatch(conferenceActions.rejectConference({ meetingId }))
    } catch (err) {
      console.log(err)
    }
  }

  const onClickClearAll = async () => {
    setIsExpand(false)
    try {
      Loading.ref.show()
      await rejectAll({})
      dispatch(conferenceActions.clearAllConference())
    } finally {
      Loading.ref.hide()
    }
  }
  if (conferenceDetails.data.length < 1) {
    return null
  }
  return (
    <Container isExpand={isExpand} isVisible={conferenceDetails.visible}>
      <Wrapper>
        {isExpand && (
          <TopSide>
            <CustomButton
              size="small"
              typography="body7"
              type="secondary"
              text={t(BUTTONS.SHOW_LESS)}
              onClick={onClickShowLess}
            />
            <CustomButton
              size="small"
              typography="body7"
              type="danger"
              text={t(BUTTONS.CLEAR_ALL)}
              onClick={onClickClearAll}
            />
          </TopSide>
        )}
        {conferenceDetails.data.map((item: TConferenceAlert, index: number) => {
          return (
            <div key={item.meetingId}>
              <Alert
                isExpand={isExpand}
                index={conferenceDetails.data.length - index}
                length={conferenceDetails.data.length}
              >
                <StyledIcon
                  iconPreset="thin"
                  onClick={() => onClickCloseIcon(index, item.meetingId)}
                  id="close-icon"
                  index={index}
                  source={icons.crossIconMid}
                />
                <Avatar
                  text={item.title}
                  onClick={() => onClickAlert(item.url, index, item.meetingId)}
                  source={item.avatar}
                  size={AvatarSize.medium}
                />
                <MidSide onClick={() => onClickAlert(item.url, index, item.meetingId)}>
                  <ContentWrapper>
                    <ContentTop>
                      <StyledCustomText typography="header3">{item.title}</StyledCustomText>
                    </ContentTop>
                    <ContentBottom>
                      <CustomText typography="body3">{t(item.description)}</CustomText>
                      <CustomIcon
                        iconPreset="thin"
                        source={
                          icons.cameraIconFilled
                        }
                      />
                    </ContentBottom>
                  </ContentWrapper>
                </MidSide>
                <CustomText typography="body7" color={colors.gray60}>
                  {moment(item.date).format(MESSAGE_DATE_FORMAT)}
                </CustomText>
              </Alert>
            </div>
          )
        })}
      </Wrapper>
    </Container>
  )
}

export default ConferenceAlert

// const StyledMiniIcon = styled(CustomIcon)`
//   width: 16px;
//   height: 16px;
// `

const ContentTop = styled.div`
  display: flex;
  align-items: center;
`

const ContentBottom = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledCustomText = styled(CustomText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
const StyledIcon = styled(CustomIcon).attrs((props: IAlertProps) => props)`
  z-index: 3;
  position: absolute;
  display: none;
  top: -6px;
  right: -6px;
`

const TopSide = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`
const Alert = styled.div.attrs((props: IAlertProps) => props)`
  width: 280px;
  height: 100px;
  display: flex;
  position: absolute;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 5px ${colors.gray60};
  background-color: ${colors.white};
  transform: translateY(0%)
    ${({ isExpand }) => (isExpand ? ' translateY(20%)' : ' translateY(10%)')};
  transition: all 700ms ease;
  &:hover {
    #close-icon {
      display: ${({ isExpand, length }) => (isExpand || length === 1 ? ' block' : 'none')};
    }
  }
  top: ${({ index, isExpand }) => {
    if (isExpand) {
      return index * 110 - 65
    } else {
      if (index <= 4) {
        return index * 10
      }
      return 40
    }
  }}px;
`

const Container = styled.div.attrs((props: IAlertContainerProps) => props)`
  position: absolute;
  z-index: 4;
  right: 0;
  top: 60px;
  height: ${({ isExpand }) => (isExpand ? '78%' : '23%')};
  min-width: 320px;
  overflow-y: auto;
  transform: translateX(0%)
    ${({ isVisible }) => (isVisible ? ' translateY(-0%)' : ' translateY(-150%)')};
  transition: all 700ms ease;
`

const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
`

const MidSide = styled.div`
  width: 150px;
  max-height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ContentWrapper = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  flex-direction: column;
`
