import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import './index.css'
import './kendo_override.css'
import 'locales/config'
import { store } from 'app/store'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import NavigateSetter from 'routes/NavigateSetter'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      {/* This allows us to use useNavigate outside of components. */}
      <NavigateSetter />
    </BrowserRouter>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
