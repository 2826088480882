import {
  useAddUserToRoleMutation,
  useLazyGetRoleExternalUsersQuery,
  useLazyGetRoleUsersQuery,
  useRemoveUserFromRoleMutation,
} from 'app/services/system.service'
import SettingsHeader from 'features/settings/SettingsHeader'
import { TITLES } from 'locales/locales'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { GridColumn } from '@progress/kendo-react-grid'
import ValueSlider from 'components/kendo/grid/ValueSlider'
const RolesUserManagement = () => {
  interface DataState {
    take: number
    skip: number
    filter: {
      logic: 'and' | 'or'
      filters: any[] 
    }
    sort?: any
  }
  interface IUser {
    _id: string
    name: string
  }
  const [lazygetRoleUsers] = useLazyGetRoleUsersQuery()
  const [lazygetRoleExternalUsers] = useLazyGetRoleExternalUsersQuery()
  const [addUserToRole] = useAddUserToRoleMutation()
  const [removeUserFromRole] = useRemoveUserFromRoleMutation()
  const [isSelectAllLeftChecked, setIsSelectAllLeftChecked] = useState(false);
  const [isSelectAllRightChecked, setIsSelectAllRightChecked] = useState(false);
  const { roleId = '' } = useParams<{ roleId: string }>()
  const { t } = useTranslation()
  const [dataState, setDataState] = useState<DataState>({
    take: 10,
    skip: 0,
    filter: {
      logic: 'and',
      filters: [],
    },
  })
  const [dataStateUnselected, setDataStateUnselected] = useState<DataState>({
    take: 10,
    skip: 0,
    filter: {
      logic: 'and',
      filters: [],
    },
  })

    const [refresh, setRefresh] = useState<boolean>(false)
    const [roleUsers, setRoleUsers] = useState<IUser[]>([])
    const [roleUsersData, setRoleUsersData] = useState<IUser[]>([])
    const [roleUnSelectedUsers, setRoleUnSelectedUsers] = useState<IUser[]>([])
    const [selected, setSelected] = useState<any>([])
    const [unSelected, setUnSelected] = useState<any>([])
    const [roleUnSelectedUsersData, setRoleUnSelectedUsersData] = useState<IUser[]>([]);
  const getRoleUsers = async () => {
    const responseRoles = await lazygetRoleUsers({
      ...dataState,
      count: true,
      id: roleId,
    })
    setRoleUsers(responseRoles.data)
    setRoleUsersData(responseRoles.data.data)
  }
  useEffect(() => {
    getRoleUsers()
  }, [dataState, refresh])
  const getRoleExternalUsers = async () => {
    const responseRoles = await lazygetRoleExternalUsers({
      ...dataStateUnselected,
      count: true,
      id: roleId,
    })
    setRoleUnSelectedUsersData(responseRoles.data.data);
    setRoleUnSelectedUsers(responseRoles.data)
  }
  useEffect(() => {
    getRoleExternalUsers()
  }, [dataStateUnselected, refresh])
  const onDataStateChange = (e: any) => {
    setDataState(e.dataState)
  }
  const onDataStateChangeUnselected = (e: any) => {
    const { skip, take, sort } = e.dataState
    setDataStateUnselected({
      ...dataStateUnselected,
      skip,
      take,
      sort,
    })
  }
  const onChangeUnSelected = (dataItem: any, isSelectedOnGrid: boolean) => {
    isSelectedOnGrid
      ? setUnSelected([...unSelected, dataItem])
      : setUnSelected(unSelected.filter((selected: any) => selected._id !== dataItem._id))
  }
  const onClickForward = async () => {
    if (unSelected.length) {
      await addUserToRole({
        _id: roleId,
        members: unSelected.map((us: any) => us._id),
      })
      setSelected([])
     
      setIsSelectAllLeftChecked(false)
      setIsSelectAllRightChecked(false)
      setRefresh(!refresh)
    }
  }

  const selectAllUnselectedUsers = () => {
    const allUnselectedUsers = roleUnSelectedUsersData.map(user => user);
    setUnSelected(allUnselectedUsers);
    const newSelectedUsers = allUnselectedUsers.filter(unselectedUser =>
      !selected.some((selectedUser: { _id: string }) => selectedUser._id === unselectedUser._id)
    );
    setSelected([...selected, ...newSelectedUsers]);
  };


  const unselectAllUsersLeft = () => {
    setUnSelected([]);
  };

  const selectAllRightTableUsers = () => {
    setSelected((prevSelected: { _id: any }[]) => {
      const combinedUsers = [...prevSelected, ...roleUsersData.filter(user => 
        !prevSelected.some((selectedUser: { _id: any }) => selectedUser._id === user._id))];
      
      return combinedUsers;
    }); 
  };
  
  const onChangeSelected = (dataItem: any, isSelectedOnGrid: boolean) => {
    isSelectedOnGrid
      ? setSelected([...selected, dataItem])
      : setSelected(selected.filter((selected: any) => selected._id !== dataItem._id))
  }
  const onClickBack = async () => {
    if (selected.length) {
      await removeUserFromRole({
        _id: roleId,
        members: selected.map((us: any) => us._id),
      })
      setUnSelected([])
      setIsSelectAllLeftChecked(false)
      setIsSelectAllRightChecked(false)
      setRefresh(!refresh)
    }
  }
  const onFilterChangeUnselected = (e: any) => {
    console.log(e)
    setDataStateUnselected({
      ...dataStateUnselected,
      filter: e.filter,
    })
  }


 const handleSelectAllLeft = () => {
      setIsSelectAllLeftChecked(prev => {
        if (!prev) {
          selectAllUnselectedUsers();
        } else {
      unselectAllUsersLeft();
        setSelected([]);
      }
      return !prev;
      });
  };
  
  const handleSelectAllRight = () => {
      setIsSelectAllRightChecked(prev => {
      if (!prev) {
        selectAllRightTableUsers();
      } else {
        setSelected([]);
      }
        return !prev;
      });
    };

  return (
      <Wrapper>
        <SettingsHeader title={t(TITLES.ROLE_SETTINGS_TITLE)} />
        <div className="buttonContainer">
        </div>
        <ValueSlider
          onSelectAllLeft={handleSelectAllLeft}
          onSelectAllRight={handleSelectAllRight}
          isSelectAllLeftChecked={isSelectAllLeftChecked}
          isSelectAllRightChecked={isSelectAllRightChecked}
          datasourceLeft={roleUnSelectedUsers}
          dataStateLeft={dataStateUnselected}
          onDataStateChangeLeft={onDataStateChangeUnselected}
          selectedListLeft={unSelected}
          onChangeSelectedLeft={onChangeUnSelected}
          onFilterChangeLeft={onFilterChangeUnselected}
          datasourceRight={roleUsers}
          dataStateRight={dataState}
          selectedListRight={selected}  
          onDataStateChangeRight={onDataStateChange}
          onChangeSelectedRight={onChangeSelected}
          onClickForward={onClickForward}
          onClickBack={onClickBack}
        >
          <GridColumn field="_id" title="_id" editable={false} filterable={false} width={130} />
          <GridColumn field="fullname" title="fullname" filterable={true} />
        </ValueSlider>
      </Wrapper>
  )
}
export default RolesUserManagement

  const Wrapper = styled.div`
    /* margin-top: 40px; */
    padding: 0px 30px;
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .buttonContainer {
      display: flex;
      justify-content: space-between;
      padding: 0 10px 0 0 ;
    }
  ` 
  const ButtonLeft = styled.button`
  padding: 4px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: #0056b3;
  }
`;

const ButtonRight = styled.button`
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #1e7e34;
  }
`;
