import { memo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { POST } from 'locales/locales'

import CustomText from 'components/text/CustomText'
import CustomIcon from 'components/icon/CustomIcon'
import CustomButton from 'components/button/CustomButton'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import CreateNewPostModal from './create-new-post-modal/CreateNewPostModal'
import { FixMeLater } from 'types'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'
import { MOBILE_DEVICE_WIDTH } from 'utils/constants'
import { useAppDispatch } from 'app/hooks'
import { newsFeedActions } from 'app/slices/news-feed.slice'

interface ICreateNewPostProps {
  avatar: string
  fullname: string
}

const CreateNewPost = ({ avatar, fullname }: ICreateNewPostProps) => {
  const { t } = useTranslation()
  const { screenWidth } = useWindowDimensions()
  const dispatch = useAppDispatch()

  const [visibleCreateNewPost, setVisibleCreateNewPost] = useState<FixMeLater>({
    visible: false,
    openBy: undefined,
  })

  const hideCreatePostModal = () => {
    setVisibleCreateNewPost({ visible: false, openBy: undefined })
    dispatch(newsFeedActions.resetInputData())
  }

  const openCreatePostModal = (openBy?: string) => {
    setVisibleCreateNewPost({ visible: true, openBy })
  }

  return (
    <Wrapper className="post">
      {visibleCreateNewPost.visible && (
        <CreateNewPostModal
          avatar={avatar}
          fullname={fullname}
          openBy={visibleCreateNewPost.openBy}
          onClose={hideCreatePostModal}
        />
      )}
      <TopWrapper>
        {screenWidth > MOBILE_DEVICE_WIDTH && (
          <Avatar source={avatar} text={fullname} size={AvatarSize.medium} type="circle" />
        )}
        <TextBox onClick={() => openCreatePostModal()}>
          <CustomText typography="body2" color={colors.gray80}>
            {t(POST.CREATE_POST)}
          </CustomText>
          <CustomIcon iconPreset="large" source={icons.sendIconWithoutBackground} />
        </TextBox>
      </TopWrapper>
      <BottomWrapper>
        <ActionButton
          text={screenWidth > MOBILE_DEVICE_WIDTH ? t(POST.ARTICLE) : ''}
          typography="body4"
          icon={icons.articleIcon}
          textColor={colors.gray100}
          onClick={openCreatePostModal}
        />
        <ActionButton
          text={screenWidth > MOBILE_DEVICE_WIDTH ? t(POST.PHOTO) : ''}
          typography="body4"
          icon={icons.imageIcon}
          textColor={colors.gray100}
          onClick={() => openCreatePostModal('photo')}
        />
        <ActionButton
          text={screenWidth > MOBILE_DEVICE_WIDTH ? t(POST.VIDEO) : ''}
          typography="body4"
          icon={icons.cameraIconFilled}
          textColor={colors.gray100}
          onClick={() => openCreatePostModal('video')}
        />
        {/* <ActionButton
          text={screenWidth > MOBILE_DEVICE_WIDTH ? t(POST.LIVE_STREAM) : ''}
          typography="body4"
          icon={icons.liveStreamIconRed}
          textColor={colors.gray100}
        /> */}
      </BottomWrapper>
    </Wrapper>
  )
}

export default memo(CreateNewPost)

const Wrapper = styled.div`
  padding: 20px 20px 10px 20px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray60};
  border-radius: 8px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-bottom: 20px;
`

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 20px;
`
const TextBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid ${colors.light80};
  border-radius: 40px;
  padding: 4px 4px 4px 16px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.light40};
  }
`

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
`

const ActionButton = styled(CustomButton)`
  box-shadow: none;
  height: 28px;
  gap: 8px;
  background-color: ${colors.white};
  @media only screen and (min-width: ${MOBILE_DEVICE_WIDTH}px) {
    padding: 0px 20px;
  }
  @media only screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    padding: 0px 12px;
  }
  &:hover {
    background-color: ${colors.light50};
  }
`
