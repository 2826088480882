import { InputData } from 'app/slices/news-feed.slice'
import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import { ERRORS } from 'locales/locales'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import {
  POST_SUPPORTED_IMAGES_TYPES,
  POST_SUPPORTED_VIDEO_TYPES,
  FILE_COUNT_ERROR_CODE,
  FILE_SIZE_ERROR_CODE,
  GB,
} from 'utils/constants'

interface ICommonInputFilesPrpos {
  inputData: InputData
  onChangeInputFileData: (items: Array<File>) => void
  isAcceptPhoto: boolean
}
const useCommonInputFiles = ({
  inputData,
  onChangeInputFileData,
  isAcceptPhoto,
}: ICommonInputFilesPrpos) => {
  const acceptedType = isAcceptPhoto ? POST_SUPPORTED_IMAGES_TYPES : POST_SUPPORTED_VIDEO_TYPES
  const { t } = useTranslation()

  const onDropAccepted = (files: Array<File>) => {
    if (
      files.length > 4 ||
      inputData.files.length > 3 ||
      files.length + inputData.files.length > 4
    ) {
      return DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.FILE_COUNT_ERROR, { count: 4 }),
      })
    }
    onChangeInputFileData(files)
  }

  const onDropRejected = (fileRejections: Array<FileRejection>) => {
    if (fileRejections[0].errors[0].code === FILE_COUNT_ERROR_CODE) {
      DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.FILE_COUNT_ERROR, { count: 4 }),
      })
    }
    if (fileRejections[0].errors[0].code === FILE_SIZE_ERROR_CODE) {
      DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.FILE_SIZE_ERROR, {
          file_name: fileRejections[0].file.name,
        }),
      })
    }
  }
  const { getInputProps, inputRef } = useDropzone({
    accept: acceptedType,
    maxFiles: 4,
    maxSize: 1 * GB,
    onDropAccepted,
    onDropRejected,
  })

  return {
    getInputProps,
    inputRef,
  }
}

export default useCommonInputFiles
