import styled from 'styled-components'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { IPost } from 'types/post.types'
import { BUTTONS, MODALS } from 'locales/locales'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { INewsFeedSlice, newsFeedActions } from 'app/slices/news-feed.slice'
import {
  useDeletePostMutation,
  useDislikePostMutation,
  useLazyGetPaginatedPostsQuery,
  useLazyGetUnreadPostsQuery,
  useLikePostMutation,
} from 'app/services/post.service'

import Post from './post/Post'
import EditPost from './edit-post/EditPost'
import Spinner from 'components/spinner/Spinner'
import useInfoModal from 'utils/hooks/useInfoModal'
import PostReactions from './_components/post-reactions/PostReactions'
import EmptyNewsFeed from './_components/empty-news-feed/EmptyNewsFeed'
import NewPostAlert from './_components/new-post-alert/NewsPostAlert'
import CreateNewPost from './create-new-post/CreateNewPost'
import Header from './_components/header/Header'
import { MOBILE_DEVICE_WIDTH } from 'utils/constants'
import { useOutletNewsFeedState } from 'utils/hooks/useOutletState'
import { NewsFeedPermissions, IRolePermissions } from 'types/role.types'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'
import { IUser } from 'types/user.types'

const NewsFeedHome = () => {
  const users = useAppSelector((state) => state.users.data)
  const myId = useAppSelector((state) => state.auth.id)
  const authUser = useAppSelector((state) => state.auth.user)

  const permissions = useAppSelector<IRolePermissions>((state) => state.auth.permissions)
  const [reactions, setReactions] = useState({ visible: false, postId: '' })
  const [editPost, setEditPost] = useState<FixMeLater>({ visible: false, post: null })
  const isPostCreator = isFlagSetIn([NewsFeedPermissions.CREATE_NEWSFEED], permissions.newsFeed)
  const hasViewNewsFeed = isFlagSetIn([NewsFeedPermissions.VIEW_NEWSFEED], permissions.newsFeed)

  const { onJumpComment, onCloseNotification, onToggleNotification, openNotifications } =
    useOutletNewsFeedState()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { openModal, closeModal, Modal } = useInfoModal()
  const {
    posts,
    pageIndex,
    totalPostCount,
    unreadNotificationCount,
    isPostLoading,
    isScrollTop,
    isRenderedBefore,
  }: INewsFeedSlice = useAppSelector((state) => state.newsFeed)

  const [deletePost] = useDeletePostMutation()
  const [likePost] = useLikePostMutation()
  const [dislikePost] = useDislikePostMutation()
  const [getUnreadPosts] = useLazyGetUnreadPostsQuery()
  const [getPostsWithPageIndex] = useLazyGetPaginatedPostsQuery()

  const newsFeedRef = useRef<HTMLDivElement>(null)

  const fetchUnreadPosts = useCallback(async () => {
    dispatch(newsFeedActions.resetUnreadPostCount())
    const { data }: FixMeLater = await getUnreadPosts({})
    dispatch(newsFeedActions.addUnreadPost(data))
    dispatch(newsFeedActions.setIsRenderedBefore(true))
    newsFeedRef?.current?.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
    dispatch(newsFeedActions.setScrollTop(false))
    dispatch(newsFeedActions.setIsRenderedBefore(true))
  }, [])

  useEffect(() => {
    dispatch(newsFeedActions.resetUnreadPostCount())
    dispatch(newsFeedActions.setPageIndex(0))
    getPostsWithPageIndex({ page: 0 })
  }, [dispatch])

  useEffect(() => {
    if (isScrollTop && !isRenderedBefore) {
      fetchUnreadPosts()
      dispatch(newsFeedActions.resetUnreadPostCount())
    }
  }, [isScrollTop])

  useEffect(() => {
    if (hasViewNewsFeed) {
      if (posts.length === 0) getPostsWithPageIndex({ page: 0 })
    }
  }, [])

  const onPaginatePosts = async () => {
    try {
      await getPostsWithPageIndex({ page: pageIndex + 1 }).unwrap()
    } catch (error) {
      console.error('Error fetching paginated posts:', error)
    }
  }

  const onShowReactions = (postId: string) => {
    setReactions({ visible: true, postId })
  }

  const onHideReactions = () => {
    setReactions({ visible: false, postId: '' })
  }

  const onReactionPost = (postId: string, isLike: boolean) => {
    if (isLike) {
      likePost({ postId, myId })
    } else {
      dislikePost({ postId, myId })
    }
    dispatch(
      newsFeedActions.updateLikeCount({ type: isLike ? 'increase' : 'descrease', myId, postId }),
    )
  }

  const onConfirmDeletePost = async (postId: string) => {
    try {
      const result: FixMeLater = await deletePost({ postId })
      if (!result.error) {
        dispatch(newsFeedActions.deletePost({ postId }))
      }
    } finally {
      closeModal()
    }
  }

  const onDeletePost = (postId: string) => {
    openModal({
      title: t(MODALS.DELETE_POST_TITLE),
      description: t(MODALS.DELETE_POST_DESCRIPTION),
      okTitle: t(BUTTONS.DELETE),
      onCancel: closeModal,
      onOk: async () => {
        await onConfirmDeletePost(postId)
      },
    })
  }

  const onShowEditPostModal = (post: IPost) => {
    setEditPost({ visible: true, post })
  }

  const onHideEditPostModal = async () => {
    setEditPost({ visible: false, post: null })
  }

  const onNavigateToPostDetail = (postId: string) => {
    navigate(postId)
  }

  const postsPrinted:string[] = []
  return (
    <Wrapper>
      <Header
        onJumpComment={onJumpComment}
        onCloseNotification={onCloseNotification}
        openNotifications={openNotifications}
        onToggleNotification={onToggleNotification}
      />
      <ScrollableDiv id="scrollable-div" ref={newsFeedRef}>
        <InfiniteScroll
          scrollThreshold="1px"
          next={onPaginatePosts}
          dataLength={posts.length}
          inverse={false}
          hasMore={totalPostCount > posts.length}
          loader={<Spinner loading />}
          scrollableTarget="scrollable-div"
        >
          {/* <Filters /> */}
          {isPostCreator && (
            <CreateNewPost avatar={authUser.avatar ?? ''} fullname={authUser.fullname} />
          )}
          <NewPostAlert newPostCount={unreadNotificationCount} onClick={fetchUnreadPosts} />
          {posts.length === 0 && !isPostLoading && <EmptyNewsFeed />}
          {posts.map((post: IPost) => {
            if(postsPrinted.includes(post._id)) return null
            
            postsPrinted.push(post._id)
            return (
              <PostWrapper className="post" key={post._id}>
                <Post
                  postId={post._id}
                  data={post.content}
                  creatorInfo={post.userData ? post.userData[post.ownerId] : ({} as IUser)}
                  sentAt={post.sentAt}
                  status={post?.status}
                  isPostLoading={isPostLoading}
                  myId={myId}
                  likes={post.likes}
                  dislikes={post.dislikes}
                  users={post.userData ?? {}}
                  commentToggle={post?.commentToggle ?? true} //if comment toggle doesn't exist before release
                  commentCount={post.commentCount}
                  onShowReactions={onShowReactions}
                  onDeletePost={onDeletePost}
                  onShowEditPostModal={() => onShowEditPostModal(post)}
                  onNavigateToPostDetail={onNavigateToPostDetail}
                  onReactionPost={onReactionPost}
                />
              </PostWrapper>
            )
          })}
        </InfiniteScroll>
        {Modal}
        {reactions.visible && <PostReactions postId={reactions.postId} onClose={onHideReactions} />}
        {editPost.visible && (
          <EditPost
            avatar={authUser?.avatar}
            fullname={authUser?.fullname}
            post={editPost?.post}
            userInfo={authUser}
            onClose={onHideEditPostModal}
          />
        )}
      </ScrollableDiv>
    </Wrapper>
  )
}

export default NewsFeedHome

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  .post {
    width: 700px;
    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }
`

const ScrollableDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
  background-color: ${colors.light40};
  height: calc(100vh - 72px);
  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    @media only screen and (min-width: ${MOBILE_DEVICE_WIDTH}px) {
      padding: 16px;
    }
    @media only screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
      padding: 8px;
    }
  }
`

const PostWrapper = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.gray60};
  border-radius: 8px;
  @media only screen and (min-width: ${MOBILE_DEVICE_WIDTH}px) {
    padding: 20px;
  }
  @media only screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    padding: 12px;
  }
`
