import styled from 'styled-components'
import KGrid from 'components/kendo/grid/KGrid'
import {  GridToolbar } from '@progress/kendo-react-grid'
import { Button } from '@progress/kendo-react-buttons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { MESSAGES, ROLES } from 'locales/locales'

interface IValueSlider {
  dataStateLeft: any
  onDataStateChangeLeft: any
  selectedListLeft: any
  onChangeSelectedLeft: any
  onFilterChangeLeft: any
  onClickForward: any
  onClickBack: any
  datasourceRight: any
  datasourceLeft: any
  dataStateRight: any
  selectedListRight: any
  onDataStateChangeRight: any
  onChangeSelectedRight: any
  onSelectAllRight?:any
  onSelectAllLeft?:any
  isSelectAllLeftChecked?:any
  isSelectAllRightChecked?:any
}

const ValueSlider: FC<React.PropsWithChildren<IValueSlider>> = ({
  children,
  datasourceLeft,
  dataStateLeft,
  onDataStateChangeLeft,
  selectedListLeft,
  onChangeSelectedLeft,
  onFilterChangeLeft,
  onClickForward,
  onClickBack,
  datasourceRight,
  dataStateRight,
  selectedListRight,
  onDataStateChangeRight,
  onChangeSelectedRight,
  onSelectAllLeft,
  onSelectAllRight,
  isSelectAllLeftChecked,
  isSelectAllRightChecked
}: any) => {
  const { t } = useTranslation()
 
  return (
    <Row>
      <Col5>
      <GridToolbar>
      <input
            type="checkbox"
            checked={isSelectAllLeftChecked}
            onChange={onSelectAllLeft} 
          />
         {t(ROLES.SELECT_ALL)}
        </GridToolbar>
          <KGrid
            datasource={datasourceLeft}
            dataState={dataStateLeft}
            onDataStateChange={onDataStateChangeLeft}
            selectedList={selectedListLeft}
            onChangeSelected={onChangeSelectedLeft}
            pageable={true}
            filterable={true}
            onFilterChange={onFilterChangeLeft}
            selectionMode={true} 
          >
              {children &&
              datasourceLeft &&
              [children].map((child) => {
                return child
              })}
          </KGrid>
      </Col5>
      <Col1>
        <Row2>
          <Button themeColor={'primary'} onClick={onClickForward}>
          {t(ROLES.FORWARD)}
          </Button>
        </Row2>
      </Col1>
      <Col1>
        <Row2>
          <Button themeColor={'primary'} onClick={onClickBack}>
          {t(ROLES.BACK)}
          </Button>
        </Row2> 
      </Col1>
      <Col5>
      <GridToolbar>
      <input
            type="checkbox"
            checked={isSelectAllRightChecked}
            onChange={onSelectAllRight} 
          />{t(ROLES.SELECT_ALL)}
        </GridToolbar>
        <KGrid
          datasource={datasourceRight}
          dataState={dataStateRight}
          selectedList={selectedListRight}
          onDataStateChange={onDataStateChangeRight}
          onChangeSelected={onChangeSelectedRight}
          selectionMode={true}
          pageable={true}
          filterable={true}
        >
              {children &&
            datasourceRight &&
            [children].map((child) => {
              return child
            })}
        </KGrid>
      </Col5>
    </Row>
  )
}

export default ValueSlider

const Row = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const Row2 = styled.div`
  margin-top: 80px;
  margin-left: 40px;
`
const Col1 = styled.div`
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
`

const Col5 = styled.div`
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
`
  