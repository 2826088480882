/* eslint-disable no-prototype-builtins */
import { useEffect, useState } from 'react'
import { IEmojiData } from 'emoji-picker-react'
import _isEmpty from 'lodash/isEmpty'

import { IFiles } from 'types/file.types'
import { useAppSelector } from 'app/hooks'
import { getInputValueByfullname, geValueByfullname } from 'utils/helpers/messageHelper'
import { FixMeLater } from 'types'
import { useDispatch } from 'react-redux'
import { newsFeedActions } from 'app/slices/news-feed.slice'
import { useCreatePostMutation, useSendCommentMutation } from 'app/services/post.service'
import axiosApi from 'app/axiosApi'
import { IUserData } from 'types/user.types'

const isEmpty = (str: string) => !str.trim().length

const inputDataValidation = (text = '', files?: IFiles, postId?: string) => {
  if (postId) return !isEmpty(text)
  return !isEmpty(text) || !_isEmpty(files)
}

const getParseMentionedUsers = (text: string) => {
  const matchedMentionedUsers = text.split('&').filter((item) => item.includes('_id'))
  const mentionedUsers = matchedMentionedUsers?.map((id) => id.slice(4))
  return mentionedUsers
}

const useCreatePostInput = ({
  inputData,
  postId,
  setComments,
  setPost,
  setTotalCommentCount,
}: {
  inputData: FixMeLater
  postId?: string
  setComments?: FixMeLater
  setPost?: FixMeLater
  setTotalCommentCount?: FixMeLater
}) => {
  const [isValid, setValid] = useState(false)
  const [createPost] = useCreatePostMutation()
  const [createComment] = useSendCommentMutation()

  const myId = useAppSelector((state) => state.auth.id)
  const [useInputData, setInputData] = useState<FixMeLater>(inputData) // using as content.

  const dispatch = useDispatch()

  const onCancelFile = (index: number) => {
    dispatch(newsFeedActions.cancelFile(index))
    setInputData((prevState: FixMeLater) => ({
      ...prevState,
      files: prevState.files.filter((item: FixMeLater, itemIndex: number) => itemIndex !== index),
    }))
  }

  const onChangeInputData = async (text: string) => {
    const mentionedUsers = getParseMentionedUsers(text)
    if (postId) {
      setInputData((prevState: FixMeLater) => ({
        ...prevState,
        text,
        value: text,
        mentionedUsers,
        postId,
      }))
      return dispatch(
        newsFeedActions.changeCommentData({ text, value: text, mentionedUsers, postId }),
      )
    }
    dispatch(newsFeedActions.changeInputData({ text, value: text, mentionedUsers }))
    
    setInputData((prevState: FixMeLater) => ({
      ...prevState,
      text,
      value: text,
      mentionedUsers,
      postId,
    }))
  }

  const onChangeHeaderInputData = async (header: string) => {
    // dispatch(newsFeedActions.changeInputData({ header }))
    setInputData((prevState: FixMeLater) => ({ ...prevState, header }))
  }

  const onChangeToggleComment = (commentToggle: FixMeLater) => {
    // dispatch(newsFeedActions.changeInputData({commentToggle}))
    setInputData((prevState: FixMeLater) => ({ ...prevState, commentToggle }))
  }

  const onChangeInputFileData = (files: FixMeLater) => {
    dispatch(newsFeedActions.changeInputFileData(files))
    setInputData((prevState: FixMeLater) => ({
      ...prevState,
      files: [...prevState.files, ...files],
    }))
  }

  const onSelectEmoji = (emojiParams: IEmojiData) => {
    if (postId) {
      dispatch(newsFeedActions.selectEmojiForComment({ ...emojiParams, postId }))
      onChangeInputData(inputData.text + emojiParams.emoji);
      return
    }
    
    dispatch(newsFeedActions.selectEmoji(emojiParams))
    onChangeInputData(inputData.text + emojiParams.emoji);
  }

  const onNewPost = async () => {

    
    const sentAt = new Date().toISOString()

    const {
      mentionedUsers,
      text,
      files = [],
      header,
      commentToggle = false,
    } = getInputValueByfullname(useInputData)
    const value = geValueByfullname(text, mentionedUsers)
    const trimmedText = text.trim()
    dispatch(
      newsFeedActions.changeInputData({
        header: header,
        text: text,
        value: text,
        mentionedUsers: mentionedUsers,
        commentToggle: commentToggle ?? false,
        files: files,
      }),
    )
    const params: {
      type: Array<string>
      sentAt: string
      commentToggle: boolean
      content: {
        header: string
        mentionedUsers: Array<string>
        text: string
        value: string
        files: Array<File>
      }
    } = {
      type: [],
      sentAt,
      commentToggle,
      content: { header, mentionedUsers, text: trimmedText, value, files },
    }
    
    if (!isEmpty(text)) {
      params.type.push('text')
    }
    if (!_isEmpty(files)) {
      params.type.push('files')
    }
    
    
    try {


      dispatch(newsFeedActions.resetInputData())
      dispatch(
        newsFeedActions.createPost({
          status: 'pending',
          ...params,
          ownerId: myId,
        }),
      )

      
      if (params.content.files.length > 0) {
        dispatch(newsFeedActions.postLoading(true))
        const files = []
        for (const file of params.content.files) {
          const result = await axiosApi.uploadFile(
            'files/?from=post&bucket=newsfeed',
            [file],
            'files',
          )
          if (result?.data?.success) {
            files.push(result?.data?.data)
          }
     
        }
        const { data }: FixMeLater = await createPost({
          ...params,
          content: { ...params.content, files },
        })

   
        if (data.success) {
          dispatch(newsFeedActions.postLoading(false))
          dispatch(newsFeedActions.createPost(data.data))
        }
      } else {
        const { data }: FixMeLater = await createPost(params)
        if (data.success) {
          dispatch(newsFeedActions.postLoading(false))
          dispatch(newsFeedActions.createPost(data.data))
        }
      }
    } catch (e) {
      console.log("Trouble creating post", e)
      dispatch(newsFeedActions.deletePost({ sentAt }))
      dispatch(newsFeedActions.postLoading(false))
    }
  }

  const onNewCommentForPost = async () => {
    const sentAt = new Date().toISOString()
    const { mentionedUsers, text } = getInputValueByfullname(useInputData)
    const value = geValueByfullname(text, mentionedUsers)
    const trimmedText = text.trim()
    const params: {
      type: Array<string>
      sentAt: string
      postId: string | undefined
      content: { mentionedUsers: Array<string>; text: string; value: string }
    } = {
      type: [],
      sentAt,
      postId: postId,
      content: { mentionedUsers, text: trimmedText, value },
    }

    if (!isEmpty(text)) {
      params.type.push('text')
    }

    try {
      dispatch(newsFeedActions.resetCommentData({ postId }))
      await createComment(params)
    } catch (e) {
      console.error('Error creating comment', e)
    }

    dispatch(newsFeedActions.commentLoading(false))
  }

  useEffect(() => {
    if (postId) {
      return setValid(inputDataValidation(useInputData?.text || '', [], postId))
    }
    setValid(inputDataValidation(useInputData?.text, useInputData?.files))
  }, [useInputData])

  return {
    isValid,
    onCancelFile,
    onNewPost,
    onSelectEmoji,
    onChangeInputData,
    onNewCommentForPost,
    onChangeInputFileData,
    onChangeHeaderInputData,
    onChangeToggleComment,
  }
}

export default useCreatePostInput
