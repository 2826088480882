import { GridColumn } from '@progress/kendo-react-grid'
import {
  useAddPermissionToRoleMutation,
  useLazyGetRolePermissionsQuery,
  useRemovePermissionFromRoleMutation,
} from 'app/services/system.service'
import ValueSlider from 'components/kendo/grid/ValueSlider'
import SettingsHeader from 'features/settings/SettingsHeader'
import { PERMISSIONS, TITLES } from 'locales/locales'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  MessagingPermissions,
  NewsFeedPermissions,
  PermissionType,
  IRolePermissions,
  SettingsPermissions,
  TeamAndChannelPermissions, UserManagementPermissions,
  VideoConferencePermissions,
} from 'types/role.types'
import { process } from '@progress/kendo-data-query'
import { Button } from '@progress/kendo-react-buttons'
import CustomText from 'components/text/CustomText'


const RolePermissionManagement = () => {
  interface DataState {
    take: number
    skip: number
    sort?: any
  }

  interface IPermission {
    _id: string;
    value: number;
    name: string;
    type: number;
    typeName: string;
  }

  const { roleId = '' } = useParams<{ roleId: string }>()
  const { t } = useTranslation()

  const [lazygetRolePermissions] = useLazyGetRolePermissionsQuery()
  const [addPermissionToRole] = useAddPermissionToRoleMutation()
  const [removePermissionFromRole] = useRemovePermissionFromRoleMutation()

  const [selectAllUnselected, setSelectAllUnselected] = useState(false);
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [unsetPermissions, setUnsetPermissions] = useState<IPermission[]>([]);
  const [dataState, setDataState] = useState<DataState>({
    take: 10,
    skip: 0
  })
  const [dataResult, setDataResult] = useState(
    process(permissions, dataState)
  );
  const [dataStateUnselected, setDataStateUnselected] = useState<DataState>({
    take: 10,
    skip: 0
  })
  const [unselectedDataResult, setUnselectedDataResult] = useState(
    process(unsetPermissions, dataStateUnselected)
  );
  const [refresh, setRefresh] = useState<boolean>(false)
  const [selected, setSelected] = useState<IPermission[]>([])
  const [unSelected, setUnSelected] = useState<IPermission[]>([])

  const [roleName, setRoleName] = useState<string>('');
  const navigate = useNavigate()

  const getRolePermissions = async () => {
    const responseRoles = await lazygetRolePermissions(roleId);
    setRoleName(responseRoles.data.data.name);

    const {set, unset} = getSetPermissions(responseRoles.data.data.permissions);
    setPermissions([...set]);
    setUnsetPermissions([...unset]);
    setDataResult(process(set, dataState));
    setUnselectedDataResult(process(unset, dataStateUnselected));
  }

  const getSetPermissions = (permissions: IRolePermissions) => {
    const set: IPermission[] = [];
    const unset: IPermission[] = [];
    updateRoleList(PermissionType.SETTINGS_PERMISSIONS, SettingsPermissions, permissions?.settings ?? 0, set, unset);
    updateRoleList(PermissionType.NEWS_FEED_PERMISSIONS, NewsFeedPermissions, permissions?.newsFeed ?? 0, set, unset);
    updateRoleList(PermissionType.MESSAGING_PERMISSIONS, MessagingPermissions, permissions?.messaging ?? 0, set, unset);
    updateRoleList(PermissionType.VIDEO_CONFERENCE_PERMISSIONS, VideoConferencePermissions, permissions?.videoConference ?? 0, set, unset);
    updateRoleList(PermissionType.TEAM_AND_CHANNEL_PERMISSIONS, TeamAndChannelPermissions, permissions?.teamAndChannel ?? 0, set, unset);
    updateRoleList(PermissionType.USER_MANAGEMENT_PERMISSIONS, UserManagementPermissions, permissions?.userManagement ?? 0, set, unset);
    return {set, unset};
  }


  const handleSelectAllUnselected = () => {
    if (!selectAllUnselected) {
      setUnSelected(unsetPermissions);
    } else {
      setUnSelected([]);
      setSelected([]);
    }
    setSelectAllUnselected(!selectAllUnselected);
  };

  const handleSelectAllSelected = () => {
    if (!selectAllSelected) {
      setSelected(permissions);
    } else {
      setSelected([]);
    }
    setSelectAllSelected(!selectAllSelected);
  };



  const updateRoleList = (permissionType: PermissionType, type: any, permissions: number, roles: IPermission[], unsetRoles: IPermission[]) => {
    const keys = Object.keys(type);
    for(const key of keys ) {
      if(!isNaN(Number(key))) {
        continue;
      }
      if((permissions & type[key]) === type[key]) {
        roles.push({
          _id: `${key}-${type[key]}`,
          name: t(PERMISSIONS[key]),
          value: type[key],
          type: permissionType,
          typeName: t(PERMISSIONS[PermissionType[permissionType]])
        });
      } else {
        unsetRoles.push({
          _id: `${key}-${type[key]}`,
          name: t(PERMISSIONS[key]),
          value: type[key],
          type: permissionType,
          typeName: t(PERMISSIONS[PermissionType[permissionType]])
        });
      }
    }
  }

  useEffect(() => {
    if(permissions && unsetPermissions && permissions?.length + unsetPermissions?.length > 0) {
      return;
    }
    getRolePermissions()
  }, [dataState, refresh, roleId, lazygetRolePermissions])

  const onDataStateChange = (e: any) => {
    setDataState(e.dataState)
    setDataResult(process(permissions, e.dataState));
  }

  const onDataStateChangeUnselected = (e: any) => {
    setDataStateUnselected(e.dataState)
    setUnselectedDataResult(process(unsetPermissions, e.dataState));
  }

  const onChangeUnSelected = (dataItem: any, isSelectedOnGrid: boolean) => {
    isSelectedOnGrid
      ? setUnSelected([...unSelected, dataItem])
      : setUnSelected(unSelected.filter((selected: any) => selected._id !== dataItem._id))
  }

  const onClickForward = async () => {
    if (unSelected.length) {
      const newPerm = [...unSelected, ...permissions];
      const newUnsetPerm = unsetPermissions.filter(u => !unSelected.includes(u));
      setPermissions(newPerm);
      setUnsetPermissions(newUnsetPerm)
      setUnSelected([]);
      setSelected([]);
      setSelectAllSelected(false)
      setSelectAllUnselected(false)
      setDataResult(process(newPerm, dataState));
      setUnselectedDataResult(process(newUnsetPerm, dataStateUnselected));
    }
  }

  const onChangeSelected = (dataItem: any, isSelectedOnGrid: boolean) => {
    isSelectedOnGrid
      ? setSelected([...selected, dataItem])
      : setSelected(selected.filter((selected: any) => selected._id !== dataItem._id))
  }

  const onClickBack = async () => {
    if (selected.length) {
      const newUnsetPerm = [...selected, ...unsetPermissions];
      const newPerm = permissions.filter(u => !selected.includes(u));
      setPermissions(newPerm);
      setUnsetPermissions(newUnsetPerm)
      setUnSelected([]);
      setSelectAllSelected(false)
      setSelectAllUnselected(false)
      setDataResult(process(newPerm, dataState));
      setUnselectedDataResult(process(newUnsetPerm, dataStateUnselected));
    }
  }

  const onFilterChangeUnselected = undefined;

  const onClickSave = async (e: any) => {
    const newPermissions: IRolePermissions = {
      settings: 0,
      messaging: 0,
      newsFeed: 0,
      teamAndChannel: 0,
      videoConference: 0,
      userManagement: 0
    };

    permissions.forEach(perm => {
      switch(perm.type) {
        case PermissionType.SETTINGS_PERMISSIONS:
          newPermissions.settings |= perm.value;
          break;
        case PermissionType.MESSAGING_PERMISSIONS:
          newPermissions.messaging |= perm.value;
          break;
        case PermissionType.NEWS_FEED_PERMISSIONS:
          newPermissions.newsFeed |= perm.value;
          break;
        case PermissionType.TEAM_AND_CHANNEL_PERMISSIONS:
          newPermissions.teamAndChannel |= perm.value;
          break;
        case PermissionType.VIDEO_CONFERENCE_PERMISSIONS:
          newPermissions.videoConference |= perm.value;
          break;
        case PermissionType.USER_MANAGEMENT_PERMISSIONS:
          newPermissions.userManagement |= perm.value;
          break;
        default:
          break;
      }
    });

    await addPermissionToRole({_id: roleId, ...newPermissions});
    setRefresh(!refresh);
  }

  const onClickCancel = (e: any) => {
    navigate('/system-settings/role-management');
  }

  return (
    <Wrapper>
      <TopContainer>
        <SettingsHeader title={t(TITLES.ROLE_SETTINGS_TITLE)} />
        <CustomText typography="header3">{roleName}</CustomText>
      </TopContainer>
      <ValueSlider
        onSelectAllLeft={handleSelectAllUnselected}
        onSelectAllRight={handleSelectAllSelected}
        isSelectAllLeftChecked={selectAllUnselected}
        isSelectAllRightChecked={selectAllSelected}
        datasourceLeft={unselectedDataResult}
        dataStateLeft={dataStateUnselected}
        onDataStateChangeLeft={onDataStateChangeUnselected}
        selectedListLeft={unSelected}
        onChangeSelectedLeft={onChangeUnSelected}
        onFilterChangeLeft={onFilterChangeUnselected}
        datasourceRight={dataResult}
        dataStateRight={dataState}
        selectedListRight={selected}
        onDataStateChangeRight={onDataStateChange}
        onChangeSelectedRight={onChangeSelected}
        onClickForward={onClickForward}
        onClickBack={onClickBack}
      >
        <GridColumn field="name" title="name" filterable={true} />
        <GridColumn field="typeName" title="type" filterable={true} />
      </ValueSlider>
      <Row>
            <Button themeColor={'primary'} onClick={onClickSave}>
              Save
            </Button>
            <Button themeColor={'error'} onClick={onClickCancel}>
              Cancel
            </Button>
      </Row>
    </Wrapper>
  )
}

export default RolePermissionManagement

const Wrapper = styled.div`
  /* margin-top: 40px; */
  padding: 0px 30px;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`
const Row = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 15px;
    gap: 10px;
  justify-content: center;
`
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 10px;
  `
