import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { POST } from 'locales/locales'
import { useAppSelector } from 'app/hooks'
import usePasteFile from 'utils/hooks/usePasteFile'
import { MOBILE_DEVICE_WIDTH } from 'utils/constants'
import { INewsFeedSlice } from 'app/slices/news-feed.slice'
import useCreatePostInput from 'utils/hooks/useCreatePostInput'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'
import useCommonInputFiles from '../../_components/common-input/useCommonInputFiles'

import CreateNewPostModalContent from './CreateNewPostModalContent'
import CustomButton from 'components/button/CustomButton'
import CustomModal from 'components/modals/CustomModal'
import { Switch } from '@progress/kendo-react-inputs'
import CustomText from 'components/text/CustomText'
import { FixMeLater } from 'types'

interface ICreateNewPostModalProps {
  onClose: () => void
  openBy?: string
  avatar: string
  fullname: string
}

const CreateNewPostModal = ({ onClose, avatar, fullname, openBy }: ICreateNewPostModalProps) => {
  const { t } = useTranslation()
  const { screenWidth } = useWindowDimensions()
  const { inputData, isPostLoading }: INewsFeedSlice = useAppSelector((state) => state.newsFeed)
  const [isAcceptPhoto, setAcceptPhoto] = useState(openBy === 'photo' ? true : false)
  const [header, setHeader] = useState("")
  const [commentToggle, setCommentToggle ] = useState(false)

  const {
    isValid,
    onCancelFile,
    onNewPost,
    onSelectEmoji,
    onChangeInputData,
    onChangeInputFileData,
    onChangeHeaderInputData,
    onChangeToggleComment
  } = useCreatePostInput({ inputData })

  usePasteFile({ onChangeInputFileData })

  const onChangeInputHeader = (e: FixMeLater) => {
    setHeader(e.value as string)
    onChangeHeaderInputData(e.value as string)
  }

  const onChangeCommentToggle = () => {
    setCommentToggle(!commentToggle)
    onChangeToggleComment(!commentToggle)
  }


  const { getInputProps, inputRef } = useCommonInputFiles({
    inputData,
    isAcceptPhoto,
    onChangeInputFileData,
  })

  const onCreateNewPost = () => {
    if (isValid && !isPostLoading) {
      onNewPost()
      onClose()
    }
  }
  useEffect(() => {
    if (openBy === 'photo' || openBy === 'video') {
      inputRef?.current?.click()
    }
  }, [])

  return (
    <CustomModal
      title={t(POST.NEW_POST)}
      closeModal={onClose}
      okTitle={t(POST.SHARE)}
      onOk={onCreateNewPost}
      overflow={'visible'}
      size="large"
      extraButtons={[
        <ActionButton
          key={'postphoto'}
          text={screenWidth > MOBILE_DEVICE_WIDTH ? t(POST.PHOTO) : ''}
          typography="body4"
          icon={icons.imageIcon}
          textColor={colors.gray100}
          isFilePicker
          getInputProps={getInputProps}
          onClick={() => setAcceptPhoto(true)}
        />,
        <ActionButton
          key={'postvideo'}
          text={screenWidth > MOBILE_DEVICE_WIDTH ? t(POST.VIDEO) : ''}
          typography="body4"
          icon={icons.cameraIconFilled}
          textColor={colors.gray100}
          isFilePicker
          getInputProps={getInputProps}
          onClick={() => setAcceptPhoto(false)}
        />,
        <div
          onClick={onChangeCommentToggle}
          key={'comment'}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Switch checked={commentToggle} defaultChecked= {false} offLabel="0" onLabel="I" size={'small'} />
          <div style={{ marginLeft: '20px' }}>
            <CustomText color={colors.gray100} cursor="pointer" typography="body4">
            {screenWidth > MOBILE_DEVICE_WIDTH ? "Yorumlar" : ''}
            </CustomText>
          </div>
        </div>,
      ]}
      showButtons
    >
      <CreateNewPostModalContent
        avatar={avatar}
        fullname={fullname}
        inputData={inputData}
        isValid={isValid}
        isPostLoading={isPostLoading}
        header={header}
        onChangeInputHeader={onChangeInputHeader}
        onClose={onClose}
        onNewPost={onNewPost}
        onCancelFile={onCancelFile}
        onSelectEmoji={onSelectEmoji}
        onChangeInputData={onChangeInputData}
      />
    </CustomModal>
  )
}

export default CreateNewPostModal

const ActionButton = styled(CustomButton)`
  box-shadow: none;
  height: 28px;
  gap: 8px;
  padding: 0px 20px;
  background-color: ${colors.white};
  &:hover {
    background-color: ${colors.light50};
  }
`
