import {
  useLazyGetMessagingFromRoleQuery,
  useSetMessagingFromRoleMutation
} from 'app/services/system.service'
import SettingsHeader from 'features/settings/SettingsHeader'
import { BUTTONS, TABLE, TITLES } from 'locales/locales'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { GridColumn } from '@progress/kendo-react-grid'
import KGrid from 'components/kendo/grid/KGrid'
import { process } from '@progress/kendo-data-query'
import CustomText from 'components/text/CustomText'
import { Button } from '@progress/kendo-react-buttons'
import { IRolePermissions, PermissionType } from 'types/role.types'

const RoleMessagingManagement = () => {
  interface DataState {
    take: number
    skip: number
  }
  interface IUser {
    _id: string
    name: string
  }
  const [lazygetRoleMessaging] = useLazyGetMessagingFromRoleQuery()
  const [setMessagingFromRole] = useSetMessagingFromRoleMutation();
  const { roleId = '' } = useParams<{ roleId: string }>()
  const { t } = useTranslation()
  const [roleName, setRoleName] = useState<string>('');

  const [refresh, setRefresh] = useState<boolean>(false)
  const [roleMessaging, setRoleMessaging] = useState<any>([])
  const [dataState, setDataState] = useState<DataState>({
    take: 10,
    skip: 0
  })
  const [dataStateSelected, setDataStateSelected] = useState<DataState>({
    take: 10,
    skip: 0
  })
  const [selected, setSelected] = useState<any>([])
  const navigate = useNavigate()

  const getRoleMessaging = async () => {
    const responseRoles = await lazygetRoleMessaging(roleId)
    setRoleName(responseRoles.data.data.name);
    console.warn('RESPONS', responseRoles.data);
    const messaging = responseRoles.data?.data?.messaging ?? [];
    setRoleMessaging(process(messaging, dataState));
    const unselect = messaging.filter((r:any) => r.selected);
    console.warn('UNSET', unselect, process(unselect, dataStateSelected));
    setSelected(unselect,);
  }
  useEffect(() => {
    getRoleMessaging()
  }, [refresh])


  const onChangeSelected = (dataItem: any, isSelectedOnGrid: boolean) => {
    isSelectedOnGrid
      ? setSelected([...selected, dataItem])
      : setSelected(selected.filter((selected: any) => selected._id !== dataItem._id))
  }

  const onClickSave = async (e: any) => {
    const messagings = {
      _id: roleId,
      messagings: selected.map((s: any) => s._id)
    }
    console.warn('SELECTEDS', messagings);
    await setMessagingFromRole(messagings);
    setRefresh(!refresh);
  }

  const onClickCancel = (e: any) => {
    navigate('/system-settings/role-management');
  }

  return (
    <Wrapper>
      <TopContainer>
        <SettingsHeader title={t(TITLES.ROLE_SETTINGS_TITLE)} />
        <CustomText typography="header3">{roleName} / {t(BUTTONS.MESSAGING)}</CustomText>
      </TopContainer>
      <KGrid datasource={roleMessaging}
             dataState={dataState}
             selectionMode={true}
             selectedList={selected}
             selectedField={"selected"}
             onChangeSelected={onChangeSelected}
      >
        <GridColumn field="name" title={t(TABLE.ROLE_NAME)} filterable={true} />
      </KGrid>
      <Row>
        <Button themeColor={'primary'} onClick={onClickSave}>
          Save
        </Button>
        <Button themeColor={'error'} onClick={onClickCancel}>
          Cancel
        </Button>
      </Row>
    </Wrapper>
  )
}
export default RoleMessagingManagement

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 10px;
  `;

const Wrapper = styled.div`
    /* margin-top: 40px; */
    padding: 0px 30px;
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .buttonContainer {
      display: flex;
      justify-content: space-between;
      padding: 0 10px 0 0 ;
    }
  `
const Row = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 15px;
    gap: 10px;
  justify-content: center;
`
