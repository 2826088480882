import { memo } from 'react'
import { POST } from 'locales/locales'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { icons } from 'utils/icons'

import CustomButton from 'components/button/CustomButton'

interface INewPostCountProps {
  newPostCount: number
  onClick: () => void
}

const NewPostAlert = ({ newPostCount, onClick }: INewPostCountProps) => {
  const { t } = useTranslation()

  if (!newPostCount) {
    return null
  }
  return (
    <Wrapper
      onClick={onClick}
      text={newPostCount + ' ' + t(POST.NEW_POST)}
      icon={icons.upArrowIcon}
      iconAlignment="right"
    />
  )
}

export default memo(NewPostAlert)

const Wrapper = styled(CustomButton)`
  display: flex;
  position: fixed;
  background-color: ${colors.vime};
  box-shadow: 0px 2px 5px ${colors.gray60};
  border-radius: 100px;
  z-index: 3;
  top: 100px;
  padding: 6px 16px;
  gap: 10px;
  align-items: center;
  box-shadow: 0px 2px 5px #adb0b0;
`
