import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { FixMeLater } from 'types'
import { emailValidation } from 'utils/helpers/validations'
import { useInitializeSystemMutation } from 'app/services/system.service'
import { BUTTONS, ERRORS, FORM, TITLES } from 'locales/locales'

import Loading from 'components/spinner/Loading'
import CustomButton from 'components/button/CustomButton'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import { getDataFromDB, setDataToDB } from 'utils/helpers/storageHelper'

const validateFullname = (fullname: string) => {
  return fullname.trim().length > 5 ? '' : t(ERRORS.INVALID_FULLNAME)
}

const validatePassword = (password: string) => {
  const trimmedPassword = password.trim()
  return trimmedPassword.length > 5 && trimmedPassword.length <= 30
    ? ''
    : t(ERRORS.INVALID_PASSWORD)
}

export default function InitialSetupForm() {
  const [formChecked, setFormChecked] = useState(false)
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    fullname: '',
  })

  const navigate = useNavigate()
  const [initializeSystem] = useInitializeSystemMutation()

  const initializeTest = async () => {
    // navigate to login if system already initialized
    // const config = JSON.parse(localStorage.getItem('config') as string)
    const config = (await getDataFromDB('vimesoft', 'config')) as any
    if (config?.isSystemInit) {
      navigate('/login')
    }
  }
  
  useEffect(() => {
    initializeTest()
  }, [])

  const onChangeEmail = (text: string) => {
    setFormState({ ...formState, email: text })
  }

  const onChangeFullname = (text: string) => {
    setFormState({ ...formState, fullname: text })
  }

  const onChangePassword = (text: string) => {
    setFormState({ ...formState, password: text })
  }

  const setNameAndFavicon = (name: string, url: string, logo: string) => {
    document.title = name
    document.getElementById('favicon')?.setAttribute('href', `${url}`)
    document.getElementById('login-logo')?.setAttribute('src', `${logo}`)
    document.getElementById('login-logo')?.setAttribute('srcSet', `${logo}`)
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setFormChecked(true)

    if (
      validatePassword(formState.password) ||
      !emailValidation(formState.email).isValid ||
      validateFullname(formState.fullname)
    ) {
      return
    }

    try {
      Loading.ref.show()
      const result: FixMeLater = await initializeSystem({
        email: formState.email?.trim(),
        password: formState.password?.trim(),
        fullname: formState.fullname?.trim(),
      })

      if (result?.data?.success) {
        // const config = JSON.parse(localStorage.getItem('config') as string)
        const configData = (await getDataFromDB('vimesoft', 'config')) as any
        // localStorage.setItem('config', JSON.stringify({ ...config, isSystemInit: true }))
        setDataToDB('vimesoft', 'config', { ...configData, isSystemInit: true })
        setNameAndFavicon(configData?.name, configData?.imageUrlFavicon, configData?.imageUrlLogo)
        navigate('/login')
      }
    } catch (error) {
      console.log(error)
    } finally {
      Loading.ref.hide()
    }
  }

  return (
    <Wrapper>
      <h1>{t(TITLES.INITIAL_SETUP)}</h1>
      <Form onSubmit={onSubmit}>
        <CustomFormInput
          value={formState.fullname}
          type="text"
          title={t(FORM.FULLNAME) as string}
          placeholder="John Doe"
          preset="medium"
          errorText={formChecked && validateFullname(formState.fullname)}
          onChange={onChangeFullname}
        />
        <CustomFormInput
          value={formState.email}
          type="email"
          title={t(FORM.ADMIN_EMAIL) as string}
          placeholder="admin@example.com"
          preset="medium"
          errorText={formChecked && emailValidation(formState.email).errorText}
          onChange={onChangeEmail}
          disabled={false}
        />
        <CustomFormInput
          value={formState.password}
          type="password"
          title={t(FORM.ADMIN_PASSWORD) as string}
          placeholder="*********"
          preset="medium"
          errorText={formChecked && validatePassword(formState.password)}
          onChange={onChangePassword}
        />
        <CustomButton typography="header1" size="large" text={t(BUTTONS.SIGN_UP) as string} />
      </Form>
    </Wrapper>
  )
}

const Form = styled.form`
  width: 372px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 70px;
`
